import { Box, Text } from "@chakra-ui/core"
import React from "react"
import { GameDetailsPageData } from "../types"
import GameBanner from "./GameBanner"

interface Props {
  data: GameDetailsPageData
}

const GamePresentationBox: React.FC<Props> = ({ data, children }) => {
  return (
    <Box backgroundColor="white" boxShadow="lg">
      <GameBanner gameId={data.gameId} videoId={data.youtubeVideoId} />
      {children}
    </Box>
  )
}

export default GamePresentationBox
