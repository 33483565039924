import React from "react"
import { Box, Center } from "@chakra-ui/core"
import GameBanner from "./GameBanner"
import { GameDetailsPageData } from "../types"
import YoutubeButton from "./YoutubeButton"
import YouTube from "react-youtube"

interface Props {
  videoId: string
}

const GameVideo: React.FC<Props> = ({ videoId }) => {
  const opts: any = {
    width: "100%",
    playerVars: {
      autoplay: 1,
    },
  }
  return (
    <Box backgroundColor="gray.900" paddingY={6}>
      <Center>
        <Box width="100%" position="relative" boxShadow="lg">
          <YouTube
            containerClassName={"youtubeContainer"}
            videoId={videoId}
            opts={opts}
          />
        </Box>
      </Center>
    </Box>
  )
}

export default GameVideo
