import React, { useState } from "react"
import { Box, Flex, Circle, Icon } from "@chakra-ui/core"
import { useStaticQuery, graphql } from "gatsby"
import { MdPlayCircleFilled } from "react-icons/md"
import Img from "gatsby-image"
import GameVideo from "./GameVideo"

interface Props {
  gameId: string
  videoId?: string
}

const GameBanner: React.FC<Props> = ({ gameId, videoId = null }) => {
  const [isPlaying, setIsPlaying] = useState(false)
  const playVideo = () => setIsPlaying(true)
  const data = useStaticQuery(graphql`
    query {
      comiquest: file(relativePath: { eq: "comiquest/banner.png" }) {
        childImageSharp {
          fluid(maxWidth: 600, quality: 25) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
          fixed(width: 300) {
            ...GatsbyImageSharpFixed_withWebp_tracedSVG
          }
        }
      }
      memoquest: file(relativePath: { eq: "memoquest/banner.png" }) {
        childImageSharp {
          fluid(maxWidth: 600, quality: 25) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
          fixed(width: 300) {
            ...GatsbyImageSharpFixed_withWebp_tracedSVG
          }
        }
      }
      ldvelh: file(relativePath: { eq: "ldvelh/banner.png" }) {
        childImageSharp {
          fluid(maxWidth: 600, quality: 25) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
          fixed(width: 300) {
            ...GatsbyImageSharpFixed_withWebp_tracedSVG
          }
        }
      }
    }
  `)

  return isPlaying && videoId ? (
    <GameVideo videoId={videoId} />
  ) : (
    <Box position="relative">
      <Img fluid={data[gameId].childImageSharp.fluid} />
      {videoId && (
        <Flex
          alignItems="center"
          justifyContent="center"
          onClick={playVideo}
          width="100%"
          height="100%"
          style={{ backgroundColor: "transparent" }}
          _hover={{ cursor: "pointer" }}
          position="absolute"
          top={0}
        >
          <Circle>
            <Icon as={MdPlayCircleFilled} boxSize={120} opacity={0.8} />
          </Circle>
        </Flex>
      )}
    </Box>
  )
}

export default GameBanner
