import React from "react"
import { Box, Center } from "@chakra-ui/core"

interface Props {
  padding?: boolean
}

const Wrapper: React.FC<Props> = ({ children, padding = true }) => {
  return (
    <Box
      maxWidth="1000px"
      margin="0 auto"
      paddingX={padding ? { base: "10px", sm: "20px" } : 0}
    >
      {children}
    </Box>
  )
}

export default Wrapper
