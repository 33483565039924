import React from "react"
import { Box, Flex, Text } from "@chakra-ui/core"

interface Props {}

const Footer: React.FC<Props> = () => {
  return (
    <Flex
      backgroundColor="gray.800"
      textColor="black"
      alignItems="center"
      direction="column"
      p={5}
    >
      <Text fontSize="sm" color="gray.400">
        ©{new Date().getFullYear()} Mad Mustache Company.
      </Text>
      <Text fontSize="sm" color="gray.400">
        All rights reserved.
      </Text>
    </Flex>
  )
}

export default Footer
